@media (max-width: 1024px) {
  .grid {
    grid-template-columns: 1fr !important;
    grid-template-rows: auto !important;
  }

  .grid-item {
    grid-area: auto !important;
  }
}
