.menu-familias .tt-desctop-parent-menu.tt-parent-box {
  width: 100%;
}

.menu-familias .tt-desctop-parent-menu.tt-parent-box .tt-desctop-menu {
  width: 100%;
}

.menu-familias
  .tt-desctop-parent-menu.tt-parent-box
  .tt-desctop-menu
  nav
  .toggle-familias-div {
  display: block;
  text-align: center;
  padding: 1em;
}

.menu-familias
  .tt-desctop-parent-menu.tt-parent-box
  .tt-desctop-menu
  nav
  .toggle-familias-div
  .toggle-familias-button {
  color: white;
}

.menu-familias
  .tt-desctop-parent-menu.tt-parent-box
  .tt-desctop-menu
  nav
  ul.familias-primer-nivel {
  display: none;
}

.menu-familias
  .tt-desctop-parent-menu.tt-parent-box
  .tt-desctop-menu
  nav
  ul.familias-primer-nivel.visible {
  display: flex;
}

.menu-familias .tt-desctop-parent-menu.tt-parent-box .tt-desctop-menu nav ul {
  justify-content: space-around;
  flex-direction: column;
}

.menu-familias
  .tt-desctop-parent-menu.tt-parent-box
  .tt-desctop-menu
  nav
  ul
  .tt-megamenu
  .dropdown-menu {
  z-index: 1;
  padding: 10px;
}

@media (min-width: 529px) {
  .menu-familias
    .tt-desctop-parent-menu.tt-parent-box
    .tt-desctop-menu
    nav
    .toggle-familias-div {
    display: none;
  }

  .menu-familias
    .tt-desctop-parent-menu.tt-parent-box
    .tt-desctop-menu
    nav
    ul.familias-primer-nivel.visible {
    display: flex;
  }

  .menu-familias
    .tt-desctop-parent-menu.tt-parent-box
    .tt-desctop-menu
    nav
    ul.familias-primer-nivel {
    display: flex;
  }

  .menu-familias .tt-desctop-parent-menu.tt-parent-box .tt-desctop-menu nav ul {
    flex-direction: row;
  }
}
