.articulo {
}

.articulo .tt-product-single-carousel-vertical {
  display: none;
}

.articulo .tt-product-single-img {
  width: 100%;
}

@media (min-width: 529px) {
  .articulo .tt-product-single-carousel-vertical {
    display: block;
  }

  .articulo
    .tt-product-single-carousel-vertical
    .slick-vertical
    .slick-list
    .slick-track {
    height: auto !important;
  }

  .articulo .tt-product-single-img {
    width: 82.7%;
  }
}
