.banner {
  margin: auto;
  margin-top: 1em;
  height: 100% !important;
}

.banner .container-indent {
  height: 100%;
}

.banner .slick-slider {
  height: 100%;
}

.banner .slick-list {
  height: 100%;
}

.banner .slick-track {
  height: 100%;
}

.banner .slick-slide {
  height: 100%;
}

.banner .slick-slide div:first-child {
  height: 100%;
}

.banner .slide {
  position: relative;
  height: 100%;
}

.banner .slide a {
  height: 100%;
}

.banner .slide a img {
  height: 100%;
}

.banner .slide .slide-caption {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
  text-align: center;
  background-color: #00000099;
  padding: 25px;
}

.banner .slide .slide-caption h3 {
  padding-bottom: 0;
  color: white;
  font-size: 1rem;
}

.banner .slide .slide-caption p {
  margin-top: 0;
  color: white;
  font-size: 1rem;
}
