.mobile-main-menu {}

.mobile-main-menu ul li>ul {
  padding-left: 1em;
}

.mm-navbtn-names {
  text-align: end;
}

.mm-closebtn {}

.tt-logo img {
  width: 25%;
  max-height: unset;
  height: auto;
}