/*
    instafeed-fluid
*/
._instafeed-default{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    @include user-select();
    a{
        position: relative;
        overflow: hidden;
        text-decoration: none;
        @include animation();
    }
    a:before{
        position: absolute;
        display: block;
        width: 100%;
        text-align: center;
        content: "\ea18";
        font-family: $default_font_icon;
        font-size:20px;
        line-height: 1;
        color: $instagram_icon;
        top: 50%;
        opacity: 0;
        z-index: 7;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
    a:after{
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        background-color: $instagram_bg;
        height: 100%;
        width: 100%;
        opacity: 0;
        @include animation-value(opacity $value_speed);
    }
    img{
        display: block;
        width: 100%;
        height: auto;
    }
    a:hover:before{
        opacity: 1;
    }
    a:hover:after{
        opacity: 0.88;
    }
}
.instafeed-fluid{
    > *{
        width: 16.666%;
        @media (max-width: 1024px){
            width: 33.333%;
        }
        @media (max-width: 575px){
            width: 50%;
        }
    }
    @extend ._instafeed-default;
}
.instafeed-col:not([class^="instafeed-item-"]){
    @media (min-width: 576px){
        margin-top: -20px;
        margin-left: -20px;
        > *{
            width: calc(25% - 20px);
            margin-top: 20px;
            margin-left: 20px;
            @media (max-width: 1024px){
                width: calc(33.333% - 20px);
            }
        }
    }
    @media (max-width: 575px){
        margin-top: -10px;
        margin-left: -10px;
        > *{
            width: calc(50% - 10px);
            margin-top: 10px;
            margin-left: 10px;
        }
    }
    @extend ._instafeed-default;
}
.instafeed-col.instafeed-item-06{
    @media (min-width: 790px){
        & > *{
            width: calc(16.6666% - 20px);
            margin-top: 20px;
            margin-left: 20px;
        }
    }
    @media (min-width: 576px) and (max-width: 789px){
        & > *{
            width: calc(25% - 20px);
            margin-top: 20px;
            margin-left: 20px;
        }
    }
    @media (max-width: 575px){
        margin-top: -10px;
        margin-left: -10px;
        > *{
            width: calc(50% - 10px);
            margin-top: 10px;
            margin-left: 10px;
        }
    }
    @extend ._instafeed-default;
}

@media (min-width: 790px){
            .instafeed-col.instafeed-col-item-06 > *{
                width: calc(16.6666% - 20px);
                margin-top: 20px;
                margin-left: 20px;
            }
        }
        @media (min-width: 576px) and (max-width: 789px){
            .instafeed-col.instafeed-col-item-06 > *{
                width: calc(25% - 20px);
                margin-top: 20px;
                margin-left: 20px;
            }
        }