#same_product_height{
    #tt-pageContent{
       .tt-product,
       .tt-product-design02{
            .tt-image-box{
                height: 200px;
                overflow: hidden;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-direction: row;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-flex-wrap: nowrap;
                -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-align-content: stretch;
                -ms-flex-line-pack: stretch;
                align-content: stretch;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                img{
                    max-height: 200px;
                    width:auto;
                    max-width: 100%;
                    display: inline-block;
                }
                .tt-img-roll-over{
                    text-align: center;
                    height: 100%;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-flex-direction: row;
                    -ms-flex-direction: row;
                    flex-direction: row;
                    -webkit-flex-wrap: nowrap;
                    -ms-flex-wrap: nowrap;
                    flex-wrap: nowrap;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -webkit-align-content: stretch;
                    -ms-flex-line-pack: stretch;
                    align-content: stretch;
                    -webkit-align-items: center;
                    -ms-flex-align: center;
                    align-items: center;
                }
            }
        }
        .tt-product,
        .tt-product-design02{
            &:not(.tt-view){
                .tt-image-box{
                    .tt-img-roll-over{
                        display: none;
                    }
                }
            }
        }
    }
}
