.control_anadir_carrito {
  width: 100%;
}

.control_anadir_carrito .input-group {
  width: 100%;
}

.control_anadir_carrito .input-group .detach-quantity-desctope {
  width: 100%;
}

.control_anadir_carrito
  .input-group
  .detach-quantity-desctope
  .tt-input-counter {
  width: 100%;
  max-width: unset;
}

.control_anadir_carrito .input-group-append {
  width: 100%;
}

.control_anadir_carrito .input-group-append button {
  width: 100%;
}

.control_anadir_carrito .mobile {
  display: block;
}

.control_anadir_carrito .desctop {
  display: none;
}

@media (min-width: 529px) {
  .control_anadir_carrito {
    width: 100%;
  }

  .articulo .control_anadir_carrito .input-group {
    width: 40%;
  }

  .control_anadir_carrito .form-control {
    width: 50%;
  }
  .control_anadir_carrito .input-group-append {
    width: 50%;
  }

  .control_anadir_carrito .mobile {
    display: none !important;
  }
  .control_anadir_carrito .desctop {
    display: flex !important;
  }
}
