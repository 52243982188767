.tt-shopcart-table{
    text-align: center !important;
}
 
.tt-shopcart-table .titulos {
    font-weight: bold;
    margin: 0.25;
  }
  
  .pedidos_anteriores_mobile,
  .pedidos_anteriores_detalle_mobile {
    display: block !important;
  }
  .pedidos_anteriores_desctop,
  .pedidos_anteriores_detalle_desctop {
    display: none !important;
  }
  
  .pedidos_anteriores_mobile .row,
  .pedidos_anteriores_detalle_mobile .row,
  .pedidos_anteriores_desctop .row,
  .pedidos_anteriores_detalle_desctop .row {
    align-items: center;
  }
  
  .pedidos_anteriores_mobile .col-sm-12.col-md-12.col-lg-12 > .row,
  .pedidos_anteriores_detalle_mobile .col-sm-12.col-md-12.col-lg-12 > .row {
    border: 1px solid gray;
  }
  
  @media (min-width: 529px) {
    .pedidos_anteriores_mobile,
    .pedidos_anteriores_detalle_mobile{
      display: none !important;
    }
    .pedidos_anteriores_desctop,
    .pedidos_anteriores_detalle_desctop {
      display: block !important;
    }
  }
  