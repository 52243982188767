form#nueva_lista {
  margin-bottom: 2em;
  align-items: baseline;
}

input#nombre_lista {
  margin-right: 1em;
}

.tt-shopcart-table {
  text-align: center !important;
}

.tt-shopcart-table .titulos {
  font-weight: bold;
  margin: 0.25;
}

.listas_compra_mobile,
.listas_compra_detalle_mobile {
  display: block !important;
}
.listas_compra_desctop,
.listas_compra_detalle_desctop {
  display: none !important;
}

.listas_compra_mobile .row,
.listas_compra_detalle_mobile .row,
.listas_compra_desctop .row,
.listas_compra_detalle_desctop .row {
  align-items: center;
}

.listas_compra_mobile .col-sm-12.col-md-12.col-lg-12 > .row,
.listas_compra_detalle_mobile .col-sm-12.col-md-12.col-lg-12 > .row {
  border: 1px solid gray;
}

@media (min-width: 529px) {
  .listas_compra_mobile,
  .listas_compra_detalle_mobile {
    display: none !important;
  }
  .listas_compra_desctop,
  .listas_compra_detalle_desctop {
    display: block !important;
  }
}
