.tt-promo-fixed{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background-color:$promo_fixed_bg;
    box-shadow: 0 0 10px $promo_fixed_shadow;
    padding: 10px;
    overflow: hidden;
    position: fixed;
    bottom:20px;
    left: 20px;
    z-index: 6;
    margin-right: 20px;
    max-width: 360px;
    @include border-radius(5px);
    @include opacity(0);
    .tt-btn-close{
        background:none;
        border: none;
        outline: none;
        color: $promo_fixed_bt_close_icon;
        font-family: $default_font_icon;
        font-size: 14px;
        position: absolute;
        right: 2px;
        top: 3px;
        display: block;
        cursor: pointer;
        @include animation-value(color $value_speed);
        &:hover{
            color: $promo_fixed_bt_close_icon_hover;
        }
    }
    .tt-btn-close:before{
        display: block;
        content: "\e9b3";
    }
    .tt-img{
        width: 80px;
        min-width: 80px;
        img{
            width: 100%;
            height: auto;
        }
         @media (max-width: 380px) {
            width: 65px;
            min-width: 65px;
        }
    }
    .tt-img + .tt-description{
        margin-left: 13px;
        margin-top: -3px;
    }
    .tt-description{
         display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: stretch;
        -ms-flex-line-pack: stretch;
        align-content: stretch;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        padding-right: 17px;
        color: $promo_fixed_text;
        width: 100%;
        .tt-title{
            font-size: 14px;
            font-weight: 400;
            font-family:$default_font;
        }
        a{
            color: $promo_fixed_link;
            display: inline-block;
            @include animation-value(color $value_speed);
            &:hover{
                 color: $promo_fixed_link_hover;
            }
        }
        p{
            margin: 0;
            font-size: 14px;
            line-height: 22px;
        }
        .tt-box-top{
            -webkit-flex: 2 1 auto;
            -ms-flex: 2 1 auto;
            flex: 2 1 auto;
            width: 100%;
        }
        .tt-info{
            color: $promo_fixed_text_info;
            font-size: 12px;
            line-height: 18px;
            margin-top: 8px;
            position: relative;
            top: 2px;
        }
        > *:nth-child(1){
            margin-top: 0;
        }
    }
}