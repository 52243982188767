.slick-slider .slick-list {
  width: 95%;
  margin: auto;
}

.slick-slider .slick-arrow.slick-prev {
  left: -5px;
  z-index: 10;
}

.slick-slider .slick-arrow.slick-prev::before {
  color: black;
}

.slick-slider .slick-arrow.slick-next {
  right: -5px;
  z-index: 10;
}

.slick-slider .slick-arrow.slick-next::before {
  color: black;
}
