/*@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,100&display=swap");*/
/*color importar fuente*/

$default_font: "Hind", sans-serif;
/*fuente web*/
$default_bg_color: #ffffff;
/*color fondo web*/
$default_text_color: #777777;
/*color defecto texto*/

$default_color: #145EAC;
/*color defecto, botones por ejemplo*/
$secondary_color: #145EAC;
/*color de los hover de los botones*/
$default_color2: #191919;
/*color defecto secundario, mejor colores oscuros*/

$h_scheme01_bg: #303030;
/*color cabecera*/

$menu_familias_bg: $default_color;

$menu_informes_bg: $default_color;

$warning: $default_color;
/*cambia el color de los warning de bootstrap usados para los avisos*/

$breadcrumb_bg: $default_bg_color;

footer .tt-color-scheme-01 {
    background: #ffffff !important;
}

footer .tt-color-scheme-01 .tt-collapse-title {
    color: black !important;
}

footer .tt-color-scheme-01 .tt-collapse-content {
    color: black !important;
}

footer .tt-color-scheme-01 .tt-list li a,
footer .tt-color-scheme-01 .tt-mobile-collapse .tt-collapse-content .tt-list li a {
    color: black !important;
}

footer .tt-color-scheme-01 .tt-collapse-content a {
    color: black !important;
}

footer .borde-izq {
    border-left: solid 1px #e8e8e8;
}

footer .tt-color-scheme-04 {
    background: #545454 !important;
    color: #e8e8e8 !important;
}

.buscador {
    margin: 2rem;
}


.banners {
    .banner {
        margin-top: 0 !important;

        .slide {
            height: 100%;
        }
    }

    .slick-slider .slick-arrow.slick-prev {
        left: 20px;
    }

    .slick-slider .slick-arrow.slick-prev::before {
        color: #e8e8e8;
    }

    .slick-slider .slick-list {
        width: 100%;
    }

    .slick-slider .slick-arrow.slick-next {
        right: 20px;
    }

    .slick-slider .slick-arrow.slick-next::before {
        color: #e8e8e8;
    }
}

.novedades_ofertas {
    .tt-product-listing>.tt-col-item {
        padding: 70px;
    }
}


.white-background {
    background-color: white !important;
}

.black-text {
    color: black !important;
}

.logo-max-size {
    max-width: 400px !important;
}


@media (max-width:1000px) {
    .tt-logo img {
        width: 100% !important
    }
}
